import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './App.css';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.API_URL = window.location.hostname.includes('localhost') ? 'http://localhost:8081' : 'http://api.prague-travel.net'

        this.prague01 = './img/prague01.avif';
        this.roka01 = './img/roka01.jpg';
        this.dublin01 = './img/dublin01.jpg';
        this.amsterdam01 = './img/amsterdam01.jpg';

        this.images = [];
        for (let i=0; i<16; i++) {
            this.images[i] = './img/faces/' + (i + 1) + '.jpg';
        }
        this.state = {
            src: this.images[0],
            curImageIndex: -1,
            stop: false,
            date: null,
            direction: null,
            selectedCity: null,
            selectedDate: null,
            isNameCorrect: false,
            directions: [],
            step: 1,
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.startImageCycle = this.startImageCycle.bind(this);
        this.stopImageCycle = this.stopImageCycle.bind(this);
        this.cycleImage = this.cycleImage.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
    }

    handleDateChange(direction, date) {
        this.setState({dateObject: date, date: date.toLocaleDateString("ru-RU"), direction: direction, curImageIndex: -1, stop: false, step: 3})
        console.log(date, direction);
    }

    startImageCycle(){
        this.intervalId = setInterval(this.cycleImage,50);
    }
    stopImageCycle(){
        this.setState({src: this.images[15], stop: true});
        clearInterval(this.intervalId);

        let body = {
            date: this.state.date,
            direction: this.state.direction,
            name: this.state.name
        };

        fetch(this.API_URL + '/send-telegram.php', {
            mode: 'no-cors',
            method: "post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        })
    }

    cycleImage(){
        if (this.state.curImageIndex >= this.images.length - 1) {
            this.setState({curImageIndex: 0});
        } else {
            this.setState({curImageIndex: this.state.curImageIndex + 1});
        }

        this.setState({src: this.images[this.state.curImageIndex]});
    }

    handleNameChange(event) {
        console.log(event.target.value);
        this.setState({name: event.target.value, isNameCorrect: ["Вика","Виктория", "Viktoriya", "Vika","вика","виктория", "viktoriya", "vika"].includes(event.target.value)})
    }

    handleCityChange(event) {
        console.log(event.target.value);
        let isCityCorrect = ["Лейрия", "лейрия", "leiria", "Leiria"].includes(event.target.value);
        this.setState({city: event.target.value, isCityCorrect: isCityCorrect})

        if (isCityCorrect) {
            this.setState({step: 2})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isNameCorrect && this.state.directions.length === 0) {
            const params = {
                mode: 'cors',
            };

            fetch(
                this.API_URL + '/fetch-directions.php',
                params
            )
                .then(response => {
                    response.json().then(
                        data => {
                            const directions = data;
                            console.log('directions', directions)
                            this.setState({directions: directions})
                        }
                    );
                })
                .catch(error => console.error(error));
        }

        if (this.state.isNameCorrect && this.state.isCityCorrect) {
            document.getElementById('step2').scrollIntoView(true);
        }

        if (this.state.direction && this.state.date) {
            document.getElementById('step3').scrollIntoView(true);
        }
    }

    render() {
        console.log(this.state);

        return (
            <div className="App">
                <header className={"App-header" + (this.state.step !== 3 ? '' : ' hidden')}>
                    <h1>
                        Добро пожаловать на сайт турагенства!
                    </h1>
                    <p>
                        Здесь у вас есть уникальная возможность спланировать свой отдых с самыми лучшими условиями
                    </p>
                    <p>
                        Мы предоставляем гида, который будет сопровождать вас на время поездки. Вы сами сможете выбрать идеальный вариант
                    </p>
                    <p>Приятного отдыха!</p>
                </header>
                <div className={"photos"}>
                    <img src={'img/faces/1.jpg'} />
                    <img src={'img/faces/2.jpg'} />
                    <img src={'img/faces/3.jpg'} />
                    <img src={'img/faces/4.jpg'} />
                    <img src={'img/faces/5.jpg'} />
                    <img src={'img/faces/6.jpg'} />
                    <img src={'img/faces/7.jpg'} />
                    <img src={'img/faces/8.jpg'} />
                    <img src={'img/faces/9.jpg'} />
                    <img src={'img/faces/10.jpg'} />
                    <img src={'img/faces/11.jpg'} />
                    <img src={'img/faces/12.jpg'} />
                    <img src={'img/faces/13.jpg'} />
                    <img src={'img/faces/14.jpg'} />
                    <img src={'img/faces/15.jpg'} />
                    <img src={'img/faces/16.jpg'} />
                </div>
                <div id={"step1"} className={"user-data" + (this.state.step === 1 ? '' : ' hidden')}>
                    <h2>Заполните, пожалуйста, небольшую анкету и мы подберем для вас лучшие варианты</h2>
                    <p><label>Ваше имя:</label> <input className={this.state.isNameCorrect ? 'correct': 'incorrect'} onChange={this.handleNameChange}/></p>
                    <p><label>Город вашего проживания:</label> <input className={this.state.isCityCorrect ? 'correct': 'incorrect'} onChange={this.handleCityChange}/></p>
                </div>

                <div id={"step2"} className={"directions " + (this.state.step === 2 ? '' : ' hidden')}>
                    <h2 id={"choose-direction-and-date"}>Теперь вы можете выбрать направление и дату поездки</h2>
                    <ol className={'directions'}>
                        {this.state.directions.map(item =>
                            <li>
                                <h3>{item.direction}</h3>
                                <img className={'teaser'} src={'img/' + item.img} alt={item.direction} />
                                <div className={"choose-date"}>
                                    Выбрать дату: <DatePicker
                                    minDate={new Date()}
                                    selected={this.state.direction === item.direction ? this.state.dateObject : null}
                                    onChange={this.handleDateChange.bind(this,item.direction)}
                                />
                                </div>
                            </li>)}
                    </ol>
                </div>

                <div id={"step3"} className={this.state.step === 3 ? '' : 'hidden'}>
                    <div className={"data-result"}>
                        {this.state.name ? <p>Ваше имя {this.state.name}</p> : null }
                        {this.state.city ? <p>Ваш город {this.state.city}</p> : null }
                        {this.state.date ? <p>Выбрана дата {this.state.date}</p> : null }
                        {this.state.direction ? <p>Выбрано направление {this.state.direction}</p> : null }
                    </div>

                    {this.state.curImageIndex === -1 && this.state.direction ? <div><h2 id={"choose-guide"}>Последний шаг - выбор лучшего гида</h2>
                        <button onClick={this.startImageCycle}>Выбрать гида</button></div> : null}
                    {
                        this.state.curImageIndex !== -1 ?
                            <div className={'face-shuffle'}>
                                <img src={this.state.src} alt={'face'}/>
                                <p>
                                    {!this.state.stop && <button className={"stop"} onClick={this.stopImageCycle}>Мне нравится этот гид - выбираю его</button>}
                                    {this.state.stop && 'Спасибо за выбор, наш гид в ближайшее время свяжется с вами. Хорошего дня!'}
                                </p>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        );
    }
}

export default App;
